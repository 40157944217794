<template>
  <div>
    <div class="flix-form-group">
      <a href="#" class="flix-btn flix-btn-default" @click.prevent="setOpen" >
        <flixIcon class="flix-share" id="share" />
        Diese Organisation teilen</a>
    </div>
    <transition name="flixFadeIn">
      <div class="flix-well" v-if="open">
        <addAccount :callback="setSuccess" />
        <div class="flix-form-group">
          <div class="flix-text-right">
            <a href="#" class="flix-html-a" @click.prevent="setOpen">{{ $t('message.close', { name: '' }) }}</a>
          </div>
        </div>
      </div>
    </transition>
    <div class="flix-form-group" v-if="success">
      <div class="flix-alert flix-alert-success">
        {{ $t('message.successfullSaved') }}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
    addAccount () { return import('./add.vue') }
  },
  props: {},
  data () {
    return {
      open: false,
      success: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSave (ret) {
      if (ret.data[0]) {
        this.success = true
        this.$store.commit('updateOrganisationMembers')
      }
    },
    setSuccess (name, rule, orga) {
      this.open = false
      this.$flix_post({
        url: 'organisation/member/set',
        data: {
          user: this.$store.getters.user.md5_id,
          name: name,
          rule: rule,
          organisation: orga
        },
        callback: this.setSave
      })
      setTimeout(function () { this.success = false }.bind(this), 3000)
    },
    setOpen () {
      this.open = !this.open
    }
  }
}
</script>
<style lang="sass" scoped>
  select
    text-transform: uppercase
</style>
